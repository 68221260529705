var NearMapInit = {
    olMap: null,
    layerType: 'Vert',
    availableSurveys: [],
    dropdownElement: null,
    selectedDisplayElement: null,
    displayedDisplayElement: null,
    config: null,

    selectedSurvey: {

        survey: null,
        get value() {
            return this.survey;
        },
        set value(value) {
            this.survey = value;
            NearMapInit.selectedDisplayElement.innerHTML = value;
        }
    },

    displayedSurvey: {
        survey: null,
        get value() {
            return this.survey;
        },
        set value(value) {
            this.survey = value;
            NearMapInit.displayedDisplayElement.innerHTML = value;
        }
    },

    TILE_SIZES: {
        North: [256, 192],
        East: [192, 256],
        South: [256, 192],
        West: [192, 256],
        Vertical: [256, 256]
    },

    toViewRotation: function (heading) {
        return NearMapMath.degreesToRadians(NearMapMath.modulus360(360 - heading));
    },

    /**
     * Provides Nearmap tile URL whenever for selected survey date and layer type
     */
    tileUrlFunction: function (tileCoord) {

        var z = tileCoord[0];
        var x = tileCoord[1];
        var y = tileCoord[2];
        return NearMapURL.urlTemplate(z, x, y, NearMapInit.displayedSurvey.value, NearMapInit.layerType, NearMapInit.config);
    },

    /**
     * Provides Nearmap tile rotation mechanism
     */
    tileLoadFunction: function (imageTile, src) {
        var img = imageTile.getImage();
        NearMapLoader.fetchImageData(src)
            .then(function (imgData) {
                if (imgData && NearMapInit.layerType !== 'Vert') {
                    NearMapLoader.rotateTile(imgData, NearMapInit.TILE_SIZES[NearMapInit.layerType], HEADINGS[NearMapInit.layerType])
                        .then(function (rotatedImgData) {
                            img.src = rotatedImgData || '';
                        });
                }

                img.src = imgData || '';
            });
    }

    /**
     * Create Openlayers projection with coresponding rotation and projection
     */
    , createView: function (zoom, center) {
        zoom = zoom || NearMapInit.config.ZOOM;
        center = center || NearMapInit.config.CENTER;


        return new ol.View({
            projection: PROJECTIONS[NearMapInit.layerType],
            rotation: NearMapInit.toViewRotation(HEADINGS[NearMapInit.layerType]),
            center: ol.proj.fromLonLat(center, PROJECTIONS[NearMapInit.layerType]),
            minZoom: NearMapInit.config.MIN_ZOOM,
            maxZoom: NearMapInit.config.MAX_ZOOM,
            zoom: zoom
        });
    }
    ,
    updateFeatureBounds: function() {
        var view = NearMapInit.olMap.getView();
        calcParams = NearMapInit.getBounds(view, 'EPSG:4326');
        var zoom = NearMapInit.olMap.getView().getZoom();



        var xminYmin = ol.proj.transform([calcParams.west, calcParams.south], 'EPSG:4326', NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString());
        var xmaxYmax = ol.proj.transform([calcParams.east, calcParams.north], 'EPSG:4326', NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString());
        var myParams = { xmin: xminYmin[0], ymin: xminYmin[1], xmax: xmaxYmax[0], ymax: xmaxYmax[1] }
        var u = encodeURIComponent(JSON.stringify(myParams));

        if (zoom > 16) {
            u = "geometry=" + u;
        } else {
            u = "objectIds=-1";
        }

        NearMapInit.olMap.getLayers().forEach(function (el) {
            if ((el.get('name') === 'parcelLayer')) {

                var s = NearMapInit.getPolygonSource(u);
                el.setSource(s);
            }
            if ((el.get('name') === 'roadsLayer')) {

                var s = NearMapInit.getLineSource(u);
                el.setSource(s);
            }
            if ((el.get('name') === 'addressLayer')) {

                var s = NearMapInit.getPointSource(u);
                el.setSource(s);
            }

        })

    },
    LayerType: null,
    getPolygonSource: function (u) {
        const url = "/API/NearMapFeatures.ashx?&" + u +
            "&returnGeometry=true&f=geojson" +
            "&AuxillaryData=" + NearMapInit.config.POLYGONCODE +
            "&QPS=" + NearMapInit.config.QPS

        const polygonLayerURL = url;
        const polygonSource = new ol.source.Vector({
            format: new ol.format.GeoJSON(),
            url: polygonLayerURL,

        });
        return polygonSource;

    }
    ,
    createParcelLayer: function (u) {
        const parcelStyle = function (feature) {
            return new ol.style.Style({
                stroke: new ol.style.Stroke({
                    color: 'blue',
                    width: 1
                }),
                text: new ol.style.Text({
                    text: feature.get(NearMapInit.config.POLYGONFIELDNAME),
                    font: 'bold 19px "Open Sans", "Arial Unicode MS", "sans-serif"',

                    fill: new ol.style.Fill({
                        color: "#042d63"
                    }),

                    stroke: new ol.style.Stroke({
                        color: "#FFFFFF",
                        width: 3
                    })

                })
            });
        };




        var polygonSource = NearMapInit.getPolygonSource(u);


        const polygonLayer = new ol.layer.Vector({
            source: polygonSource
            , style: parcelStyle
            , declutter: true

        });
        polygonLayer.set('name', "parcelLayer");
        return polygonLayer;

    },
    getLineSource: function (u) {


        const url = "/API/NearMapFeatures.ashx?" + u +
            "&returnGeometry=true&f=geojson" +
            "&AuxillaryData=" + NearMapInit.config.LINECODE +
            "&QPS=" + NearMapInit.config.QPS


        const lineSource = new ol.source.Vector({
            format: new ol.format.GeoJSON(),
            url: url
        });

        return lineSource;

    },
    createRoadLayer: function (u) {
        var projectionCC = ol.proj.get(NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString());

        const line_style = function (feature) {


            return new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'black'
                }),
                stroke: new ol.style.Stroke({
                    color: "#f2be00",
                    width: 2
                }),
                text: new ol.style.Text({
                    text: feature.get(NearMapInit.config.LINEFIELDNAME),
                    font: 'bold 19px "Open Sans", "Arial Unicode MS", "sans-serif"',
                    placement: 'line',
                    fill: new ol.style.Fill({
                        color: "#042d63"
                    }),

                    stroke: new ol.style.Stroke({
                        color: "#FFFFFF",
                        width: 3
                    })
                })
            });
        };


        var lineSource = NearMapInit.getLineSource(u);

        const lineLayer = new ol.layer.Vector({
            source: lineSource,
            style: line_style,
            declutter: true
        });

        lineLayer.set('name', 'roadsLayer');
        return lineLayer;




    },
    getPointSource: function (u) {
        var projectionCC = ol.proj.get(NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString());
        const pointLayerName = NearMapInit.config.ADDRESSLAYER;
        const url = "/API/NearMapFeatures.ashx?" + u +
            "&returnGeometry=true&f=geojson" +
            "&AuxillaryData=" + NearMapInit.config.POINTCODE +
            "&QPS=" + NearMapInit.config.QPS

        const pointSource = new ol.source.Vector({
            format: new ol.format.GeoJSON({
                defaultDataProjection: projectionCC
            }),
            url: url
        });

        return pointSource;
    },
    createAddressLayer: function (u) {
        var projectionCC = ol.proj.get(NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString());
        const addressStyle = function (feature) {
            return new ol.style.Style({
                image: new ol.style.Circle({
                    radius: 5,
                    fill: new ol.style.Fill({ color: 'red' })
                }),

                text: new ol.style.Text({
                    text: feature.get(NearMapInit.config.POINTFIELDNAME).toString(),
                    font: "italic 12px sans-serif",
                    offsetX: 10,
                    textAlign: "left",
                    fill: new ol.style.Fill({
                        color: "#000000"
                    }),

                    stroke: new ol.style.Stroke({
                        color: "#FFFFFF",
                        width: 3
                    })
                })
            });
        };



        var pointSource = NearMapInit.getPointSource(u);
        const pointLayer = new ol.layer.Vector({
            source: pointSource,
            style: addressStyle,
            declutter: true
        });
        pointLayer.set('name', "addressLayer");


        return pointLayer;




    }
    /**
     * Create Openlayers tileLayer with coresponding tileSize and projection
     */
    , createLayer: function () {

        return new ol.layer.Tile({
            source: new ol.source.XYZ({
                projection: PROJECTIONS[NearMapInit.layerType],
                tileSize: NearMapInit.TILE_SIZES[NearMapInit.layerType],
                tileUrlFunction: NearMapInit.tileUrlFunction,
                tileLoadFunction: NearMapInit.tileLoadFunction
            })
        });
    }

    /**
     * Calculates view bounds [minX, minY, maxX, maxY] for Nearmap coverage API.
     * Above coordinates are internally called [west, south, east, north].
     */
    , getBounds: function (view, projCode) {

        var projection = view.getProjection();

        var extent = null;
        if (NearMapInit.olMap) {
            extent = view.calculateExtent(NearMapInit.olMap.getSize());
        } else {
            extent = view.calculateExtent([921, 500]);

        }

        extent = ol.proj.transformExtent(extent, projection, ol.proj.get(projCode));
        var west = extent[0];
        var south = extent[1];
        var east = extent[2];
        var north = extent[3];
        var ext = { north: north, east: east, west: west, south: south }
        return ext;
    }
    /**
        * Called when the selected survey date does not exist in the current list of available survey dates.
        * It returns the closest available date.
        *
        * @param {*} surveys            available surveys returned by the coverage API
        * @param {*} selectedDate       user's selected survey date
        */
    , findClosestDate: function (surveys, selectedDate) {
        var selectedDateInMs = selectedDate ? +new Date(selectedDate) : +new Date();
        var deltaInMs = surveys.map(function (survey) {
            var surveyDateInMs = +new Date(survey.captureDate);
            return Math.abs(selectedDateInMs - surveyDateInMs);
        });

        var closestDateInMs = Math.min.apply(null, deltaInMs);
        return surveys[deltaInMs.findIndex(function (ms) { return ms === closestDateInMs; })].captureDate;
    }


    /**
     * @param {*} NearMapInit.availableSurveys    available surveys returned by the coverage API
     * @param {*} selectedDate        user's selected survey date
     */
    , getSurveyDate: function (availableSurveys, selectedDate) {
        // No dates available
        if (NearMapInit.availableSurveys.length === 0) {
            return null;
        }

        // Selects the selected survey date when available
        if (NearMapInit.availableSurveys.find(function (survey) { return selectedDate === survey.captureDate; })) {
            return selectedDate;
        }

        // Searches for the closest available survey date when not available
        return this.findClosestDate(NearMapInit.availableSurveys, selectedDate);
    }

    /**
     * Fetches Nearmap coverage API.
     */
    , fetchCoverage: function () {
        var view = NearMapInit.olMap.getView();
        this.bounds = this.getBounds(view, 'EPSG:4326');
        this.coverageUrl = NearMapURL.coverageUrlTemplate(this.bounds.east, this.bounds.west, this.bounds.north, this.bounds.south, NearMapInit.config);

        return fetch(this.coverageUrl)
            .then(function (response) {
                return response.json();
            });
    }

    /**
     * Refreshes the map tiles whenever the selected survey date changes.
     */
    , refreshTiles: function () {
        NearMapInit.olMap
            .getLayers()
            .item(0)
            .getSource()
            .refresh();
    }

    /**
     * Refreshes the map tiles whenever projection changes.
     */
    , refreshView: function () {
        var currentProject = NearMapInit.olMap.getView().getProjection();
        var currentCenter = NearMapInit.olMap.getView().getCenter();
        var zoom = NearMapInit.olMap.getView().getZoom();
        var center = ol.proj.toLonLat(currentCenter, currentProject);
        calcParams = this.getBounds(NearMapInit.olMap.getView(), 'EPSG:4326');
        var xminYmin = ol.proj.transform([calcParams.west, calcParams.south], 'EPSG:4326', NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString());
        var xmaxYmax = ol.proj.transform([calcParams.east, calcParams.north], 'EPSG:4326', NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString());
        var myParams = { xmin: xminYmin[0], ymin: xminYmin[1], xmax: xmaxYmax[0], ymax: xmaxYmax[1] }
        const u = "geometry=" + encodeURIComponent(JSON.stringify(myParams));

        return NearMapInit.updateSurveys().then(function () {
            NearMapInit.olMap.setView(NearMapInit.createView(zoom, center));
            NearMapInit.olMap.getLayers().clear();
            NearMapInit.olMap.getLayers().push(NearMapInit.createLayer());
            if (NearMapInit.config.POLYGONMAPSERVERLAYERID != '#unknown#') {
                NearMapInit.olMap.getLayers().push(NearMapInit.createParcelLayer(u));
            }
            if (NearMapInit.config.POINTMAPSERVERLAYERID != '#unknown#') {
                NearMapInit.olMap.getLayers().push(NearMapInit.createAddressLayer(u));
            }
            if (NearMapInit.config.LINEMAPSERVERLAYERID != '#unknown#') {
                NearMapInit.olMap.getLayers().push(NearMapInit.createRoadLayer(u));
            }
            //if it starts flaking out with the vector layers,then you can add them only with the layerType ==='vert'
            //if (layerType === "Vert") {
            //    NearMapInit.olMap.getLayers().push(NearMapInit.createParcelLayer());
            //    //olMap.getLayers().push(myLayer2);
            //}
        });
    }

    /**
     * updateSurveys contains logics how to deal with coverage api while map is moving
     */
    , updateSurveys: function () {
        // Fetches Nearmap coverage API based current view port
        return this.fetchCoverage()
            .then(function (response) {
                // Updates internal `NearMapInit.availableSurveys` and `displayedSurvey` members
                NearMapInit.availableSurveys = NearMapInit.getAvailableSurveyDates(response);
                NearMapInit.displayedSurvey.value = NearMapInit.getSurveyDate(NearMapInit.availableSurveys, NearMapInit.selectedSurvey.value);
                // Updates available surveys dropdown options
                NearMapInit.updateDropDown();
            });
    }

    /**
     * Displays all available survey dates in a dropdown.
     */
    , updateDropDown: function () {
        // Clears up previous options
        NearMapInit.dropdownElement.innerHTML = '';

        // Creates the content of options for select element
        NearMapInit.availableSurveys.forEach(function (survey) {
            var optionElement = document.createElement('option');
            optionElement.setAttribute('value', survey.captureDate);
            optionElement.innerText = survey.captureDate;
            NearMapInit.dropdownElement.add(optionElement);
        });

        // Assigns default select value
        NearMapInit.dropdownElement.value = NearMapInit.displayedSurvey.value;
        NearMapInit.selectedDisplayElement.value = NearMapInit.displayedSurvey.value;
        NearMapInit.selectedSurvey.value = NearMapInit.displayedSurvey.value;

    }


    , getAvailableSurveyDates: function (response) {
        this.surveys = response && response.surveys ? response.surveys : [];

        return this.surveys.filter(function (survey) {
            return (survey.resources.tiles || [])
                .some(function (tile) { return tile.type === NearMapInit.layerType; });
        });
    }

    , onMapMoveHandler: function () {
        this.updateSurveys();
        this.updateFeatureBounds();

    }
    , onMapClickHandler: function (event) {

    }

    , initUiElements: function () {

        NearMapInit.dropdownElement = document.querySelector('#availableSurveys');
        NearMapInit.selectedDisplayElement = document.querySelector('#selectedSurveyElementId');
        NearMapInit.displayedDisplayElement = document.querySelector('#displayedSurveyElementId');
    }

    /**
     * Adds event listeners to map instance and UI elements.
     */
    , addEventListeners: function () {
        // Adds map moving (panning and zooming) listener
        NearMapInit.olMap.on('moveend', function () {
            NearMapInit.onMapMoveHandler(NearMapInit.dropdownElement);
        });

        // Adds "onChange" listener to the dropdown
        NearMapInit.dropdownElement.addEventListener('change', function (evt) {
            NearMapInit.selectedSurvey.value = evt.target.value;
            NearMapInit.displayedSurvey.value = evt.target.value;

            NearMapInit.refreshTiles();
        });

        NearMapInit.olMap.on("click", function (event) {
            NearMapInit.onMapClickHandler(event)
        });

    }

    , initMap: function () {
        proj4.defs(NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString(), NearMapInit.config.MAPSERVICEPROJECTION.WKT);
        ol.proj.proj4.register(proj4);
        var initView = NearMapInit.createView();

        calcParams = this.getBounds(initView, 'EPSG:4326');

        var xminYmin = ol.proj.transform([calcParams.west, calcParams.south], 'EPSG:4326', NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString());
        var xmaxYmax = ol.proj.transform([calcParams.east, calcParams.north], 'EPSG:4326', NearMapInit.config.MAPSERVICEPROJECTION.SRID.toString());
        var myParams = { xmin: xminYmin[0], ymin: xminYmin[1], xmax: xmaxYmax[0], ymax: xmaxYmax[1] }
        const u = "geometry=" + encodeURIComponent(JSON.stringify(myParams));

        var mapLayers = [];
        mapLayers.push(NearMapInit.createLayer(initView));
        if (NearMapInit.config.POINTMAPSERVERLAYERID != '#unknown#') {
            mapLayers.push(NearMapInit.createAddressLayer(u));
        }
        if (NearMapInit.config.LINEMAPSERVERLAYERID != '#unknown#') {
            mapLayers.push(NearMapInit.createRoadLayer(u));
        }
        if (NearMapInit.config.POLYGONMAPSERVERLAYERID != '#unknown#') {
            mapLayers.push(NearMapInit.createParcelLayer(u))

        }

        NearMapInit.olMap = new ol.Map({
            target: 'map',
            controls: [new ol.control.Zoom()],
            layers: mapLayers,
            view: initView

        });

        NearMapInit.initUiElements();
        NearMapInit.addEventListeners();
    },


    init: function (config) {
        NearMapInit.config = config;


        NearMapProjections.addProj('NMV:000', 256, 256);
        NearMapProjections.addProj('NMO:NS', 256, 192);
        NearMapProjections.addProj('NMO:EW', 192, 256);
        this.initMap()
    }

    ////end init.js
}
