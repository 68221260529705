(function ($, w, undefined) {
  if (w.footable === undefined || w.foobox === null)
    throw new Error('Please check and make sure footable.js is included in the page and is loaded prior to this script.');

    var defaults = {
        enabled: true,
        selectable: true,
        selectalltooltip:""
    };

  function SelectAll(ftmid) {
    var p = this;
    p.name = 'Footable Select-All Plugin';
      p.init = function (ft) {

          var createSelectAll = function (ftmid) {

              var selectAllBox = $(
                  "<fieldset>" +
                  "   <legend></legend>" +
                  "   <div>" +
                  "       <input type='checkbox' name='selectAll' id='chk" + ftmid + "' title='" + ft.options.selectalltooltip +  "'>" +
              "   <label for='chk"+ftmid+"'>Select All</label>" +
                 "</div>" +
              "</fieldset>" 
                   );
              $table.before(selectAllBox);
              selectAllBox.change(function (evt) {
           //   selectAllBox.on("click", function (evt) {
                  var checked = $('#chk' + ftmid)[0].checked;
                  $(":checkbox", $(selectAllBox).parent()).each(function () {
       
                     this.checked = checked

                  });

              })
          }
        // INIT: 
        $(ft.table).bind({
            'footable_initialized': function (e) {
                var ftmid = ft.table.attributes['ftmid'] && ft.table.attributes['ftmid'].value;
                ft.options.selectable = (ft.table.attributes["data-selectable"])?ft.table.attributes["data-selectable"].value:null;

                ft.options.selectalltooltip = (ft.table.attributes["data-select-all-tooltip"])?ft.table.attributes["data-select-all-tooltip"].value:null;
            

                if (ftmid) {
                    if (ft.options.selectable) {
                          if (typeof ($table) !== 'undefined') {
                              if ($(':checkbox', $table).length > 1) {
                                  createSelectAll(ftmid);
                              }
                         }

                    }
                }

                /*
                   Bind to relevant events here to modify/add functionality to Footable, example:
        
                    $(ft.table).bind({
                      'footable_initialized': function(e){
                        if (e.ft.options.myPlugin.enabled === true){
                          alert('Hello World');
                        }
                      }
                    });
        
                   This would listen for the 'footable_initialized' event and when called check if the plugin is enabled
                   and if it is alert 'Hello World' to the user.
                */
            }
      });
    };
  }
  
  w.footable.plugins.register(SelectAll, defaults);
  
})(jQuery, window);