// based on some vague ideas stolen from http://6edesign.github.io/JSONTable/

(function ($, w, undefined) {
    if (w.footable === undefined || w.footable === null) {
        throw new Error('Please check and make sure footable.js is included in the page and is loaded prior to this script.');
    }

    var defaults = {
        enabled: true
    };


    function ColumnPicker() {
        var p = this;
        p.name = 'Footable Column-Picker Plugin';

        p.init = function (ft) {

            var createContextMenu = function (ftmid) {
                var cols = ft.columns;
                var $table = $(ft.table);
                var pickerLabel = 'Columns';

                if (typeof ($table[0].attributes['ColumnPickerTitleOverride']) !== 'undefined') {

                    pickerLabel = ($table[0].attributes['ColumnPickerTitleOverride'].value);
                }
                //create a bootstrap dropdown control:
                var pickerButton = $(
                    '<div class="btn-group footable-column-picker-btn">' +
                    '  <button type="button" class="btn btn-default btn-xs dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Pick the columns that you\'d like in the table.  All collapsed data can be viewed by clicking on the row.">' +
                    '    <span class="glyphicon glyphicon-list"></span>' +
                    ' ' + pickerLabel + ' <span class="caret"></span>' +
                    '  </button>' +
                    '  <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-label="Select columns to display">' +
                    '  </ul>' +
                    '</div>');

                $table.before(pickerButton);

                pickerButton.on('show.bs.dropdown', function () {

                    $("button", pickerButton).attr("aria-expanded", "true");

                    // repopulate the dropdown based on the columns - 

                    var ul = $("ul", pickerButton);
                    ul.empty(); //remove the old entries

                    var currentBreakpointName = $table.data('breakpoint');


                    var clickToggleHandler = function (e) {

                        $("button", pickerButton).attr("aria-expanded", "false");

                        var $target = $(e.currentTarget);
                        var colIndex = $target.data("columnIndex");
                        var col = ft.columns[colIndex];

                        var originalState = col.hide[currentBreakpointName];
                        col.hide[currentBreakpointName] = !originalState;

                        // track changes from default behavior
                        if (col.origHide) {
                            //tracking enabled:
                            if (col.hide[currentBreakpointName] != col.origHide[currentBreakpointName]) {
                                // flag difference to localstorage[FTCOL-{ftmid}-{name}]
                                setLsFlag(ftmid, col.name, currentBreakpointName, col.hide[currentBreakpointName]);
                            } else {
                                // clear localstorage[FTCOL-{ftmid}-{name}]
                                setLsFlag(ftmid, col.name, currentBreakpointName, null);
                            }
                        }

                        // invoke ft.resize(true) to cement our changes in the footable

                        $table.removeData('footable_info');  //this clears the previous resize info - so the next resize event will recalc everything
                        $table.trigger('footable_resize'); // recalc columns, visibility, etc

                        $table.trigger('footable_redraw');

                        ul.empty(); //remove the old entries - no need to keep those around while nobody is looking

                        e.preventDefault();
                    };


                    for (var i in ft.columns) {
                        var col = ft.columns[i];
                        if (col.name && col.name !== "B_RawParcelID") {

                            var li = $('<li></li>');
                            li.data('columnIndex', i);

                            var href = $('<a href="#" role="menuitemcheckbox"></a>');
                            href.text(' ' + col.name);

                            var isHidden = col.hide[currentBreakpointName];
                            if (isHidden) {
                                href.attr('aria-checked', 'false');
                            } else {
                                var chkGlyph = $('<span class="glyphicon glyphicon-ok"></span>');
                                href.prepend(chkGlyph);
                                href.attr('aria-checked', 'true');
                            }
                            li.append(href);
                            ul.append(li);

                            li.click(clickToggleHandler);
                            Beacon.WCAG.addSpacebarSupport(href);
                        }
                    }

                });

                pickerButton.on('hide.bs.dropdown', function () {
                    // wcag - return focus to the menu button after it closes
                    $("button", pickerButton).focus();
                });

            };

            var lsKey = function (ftmid, name, breakpointName) {
                var bpName2 = breakpointName.replace(/footable-/g, "");
                return ['FT-', ftmid, '-', name, '-', bpName2].join('');
            };

            var setLsFlag = function (ftmid, name, breakpointName, hidden) {
                var key = lsKey(ftmid, name, breakpointName);
                if (hidden == null) {
                    Beacon.localStorage.removeItem(key);
                } else {
                    Beacon.localStorage.setItem(key, hidden);
                }
            };

            var getLsFlag = function (ftmid, name, breakpointName) {
                var key = lsKey(ftmid, name, breakpointName);
                var v = Beacon.localStorage.getItem(key);
                if(v=='true') return true;
                if(v=='false') return false;
                return null;
            };

            var initializeFromLocalStorage = function (ftmid) {

                var cols = ft.columns;
                _.forEach(cols, function (col) {
                    // store original hide value for tracking user prefs
                    col.origHide = _.clone(col.hide);

                    // set if difference is stored in LS
                    var vDefault = getLsFlag(ftmid, col.name, 'default');
                    var vMedium = getLsFlag(ftmid, col.name, 'footable-medium');
                    var vSmall = getLsFlag(ftmid, col.name, 'footable-small');

                    if (vDefault != null) col.hide['default'] = vDefault;
                    if (vMedium != null) col.hide['footable-medium'] = vMedium;
                    if (vSmall != null) col.hide['footable-small'] = vSmall;

                });

                var $table = $(ft.table);
                $table.removeData('footable_info');  //this clears the previous resize info - so the next resize event will recalc everything
                $table.removeData('breakpoint'); // this will make the resize trigger a redraw

                $table.trigger('footable_resize'); // recalc columns, visibility, etc
                //$table.trigger('footable_redraw'); // resize already might be doing this?

            };

            // INIT: 
            
            $(ft.table).bind({
                'footable_initialized': function (e) {

                    var ftmid = ft.table.attributes['ftmid'] && ft.table.attributes['ftmid'].value;

                    if (ftmid) {

                        // set up hide/show from localstorage
                        initializeFromLocalStorage(ftmid);

                        // create the column picker button
                        createContextMenu(ftmid);

                    }

                }
            });
        };
    };

    w.footable.plugins.register(ColumnPicker, defaults);

})(jQuery, window);