
///math.js
/** Convert degress to radians */
var NearMapMath = {

degreesToRadians:function(deg) {
    return deg * (Math.PI / 180);
}

/** Convert radians to degrees */
,radiansToDegrees : function(rad) {
    return rad / (Math.PI / 180);
}


/** Calculate the angle remainder from 360 */
,modulus360: function (deg) {
    if (deg < 0) {
        return 360 + (deg % 360);
    }

    return deg % 360;
}
}
        ///end math.js