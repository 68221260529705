////url.js
var NearMapURL= {

    /**
    * Documentation for this Nearmap API:
    * https://docs.nearmap.com/display/ND/Tile+API
    */
    urlTemplate: function (z, x, y, survey, layer, config) {
        var until = '';

        if (survey) {
            until = '&until=' + survey;
        }

        return 'https://api.nearmap.com/tiles/v3/' +
            layer + '/' + z + '/' + x + '/' + y +
            '.img?tertiary=satellite&apikey=' + config.CODE + until;
    }

    /**
     *  Documentation for this Nearmap API:
     *  https://docs.nearmap.com/display/ND/Coverage+API
     */
    ,coverageUrlTemplate: function (east, west, north, south,config) {
        return 'https://api.nearmap.com/coverage/v2/poly/' +
            west + ',' + north + ',' +
            east + ',' + north + ',' +
            east + ',' + south + ',' +
            west + ',' + south + ',' +
            west + ',' + north +
            '?apikey=' + config.CODE +
            '&limit=1000';
    }
}
        ///end url.js